import projectAthena from '../assets/project-athena-1.svg';
import elon from '../assets/elon.png';
import elon2 from '../assets/elon2.png';
import elon3 from '../assets/elon3.png';
import elon4 from '../assets/elon4.png';
import elon5 from '../assets/elon5.png';
import downArrow from '../assets/downArrow.svg';



const Images = {
    projectAthena,
    elon,
    elon2,
    elon3,
    elon4,
    elon5,
    downArrow
};

export default Images
