import React from 'react';

export default function PageNotFound() {
  return (
    <main>
      <article id='community-article-1'>
          <h1>404</h1>
          <p>Page Not Found</p>
      </article>
    </main>
  )
}
